import Axios from 'axios'
import PrismApiError from './PrismApiError'
import Logger from '../utils/Logger'
import { PrismAppPublicModel } from './Models'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

export default class ApiManager {
    getAllPublicApps(): Promise<{ apps: PrismAppPublicModel[] }> {
        return this.getImpl('/api/v1/public/apps')
    }

    private getImpl(path: string) {
        return Promise.resolve() //
            .then(function () {
                return Axios.get(`${BASE_URL}${path}`, {
                    headers: {}
                })
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (responseData.status === 100) {
                    return responseData.data
                }

                if (responseData.status) {
                    throw new PrismApiError(`Error ${responseData.status} - ${responseData.description}`)
                }

                Logger.error(responseData)

                if (responseData.status) {
                    throw new PrismApiError(`Unexpected data from the api`)
                }
            })
    }

    private postImpl(path: string, data: any) {
        return Promise.resolve() //
            .then(function () {
                return Axios.post(`${BASE_URL}${path}`, data, {
                    headers: {}
                })
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (responseData.status === 100) {
                    return responseData.data
                }

                if (responseData.status) {
                    throw new PrismApiError(`Error ${responseData.status} - ${responseData.description}`)
                }

                Logger.error(responseData)

                if (responseData.status) {
                    throw new PrismApiError(`Unexpected data from the api`)
                }
            })
    }
}
