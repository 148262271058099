import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import * as GlobalActions from '../redux/actions/GlobalActions'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import ApiManager from '../api/ApiManager'
import { PrismAppPublicModel } from '../api/Models'
import Toaster from '../ui/Toaster'
import AppsTable from './AppsTable'
import PrismHeader from './PrismHeader'

interface RootPageInterface extends RouteComponentProps<any> {
    rootElementKey: string
    emitSizeChanged: () => void
    isMobile: boolean
}

class PageRoot extends Component<
    RootPageInterface,
    {
        collapsed: boolean
        apiData: PrismAppPublicModel[] | undefined
        isLoading: boolean
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            collapsed: false,
            apiData: undefined,
            isLoading: true
        }
    }

    updateDimensions = () => this.props.emitSizeChanged()

    componentWillUnmount() {
        if (super.componentWillUnmount) {
            super.componentWillUnmount()
        }
        this.updateDimensions()
        window.removeEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.location.pathname !== prevProps.location.pathname && this.props.isMobile) {
            this.setState({ collapsed: true })
        }
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
        this.fetchData()
    }

    fetchData() {
        const self = this
        new ApiManager()
            .getAllPublicApps() //
            .then(function(data) {
                self.setState({ apiData: data.apps })
            })
            .catch(err => Toaster.toast(err))
            .then(function() {
                self.setState({ isLoading: false })
            })
    }
    render() {
        const self = this

        if (self.state.isLoading) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <Row align="middle" justify="center">
                        Loading...
                    </Row>
                </div>
            )
        }

        const apps = self.state.apiData

        if (!apps) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <Row align="middle" justify="center">
                        Error retrieving data...
                    </Row>
                </div>
            )
        }

        return (
            <div>
                <PrismHeader />
                <Row align="middle" justify="center">
                    <Col xs={{ span: 24 }}>
                        <AppsTable apps={apps} />
                    </Col>
                </Row>
                <div style={{ height: 60 }} />
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        rootElementKey: state.globalReducer.rootElementKey,
        isMobile: state.globalReducer.isMobile
    }
}

export default connect(mapStateToProps, {
    emitSizeChanged: GlobalActions.emitSizeChanged
})(PageRoot)
