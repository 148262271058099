import PrismApiError from '../api/PrismApiError'
import Logger from '../utils/Logger'
import { message } from 'antd'

export default class Toaster {
    static toast(err: any) {
        const apiError = err as PrismApiError
        if (apiError.getApiErrorMessage && apiError.getApiErrorMessage()) {
            this.toastImpl(apiError.getApiErrorMessage())
        } else {
            Logger.error(err)
            this.toastImpl('Unknown error!')
        }
    }

    static success(messageSuccess: string) {
        message.success(messageSuccess)
    }

    private static toastImpl(errorMessage: string) {
        message.error(errorMessage)
    }
}
