import { Component } from 'react'
import React from 'react'
import { Row, Col } from 'antd'

export default class PrismHeader extends Component<{}, {}> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="prism-header">
                <a href="https://www.peermodelsnetwork.com/">
                    <Row>
                        <Col xs={{ span: 21, offset: 3 }}>
                            <img
                                src="/static/img/logo.png"
                                alt="Logo"
                                style={{
                                    marginLeft: 30,
                                    marginTop: 30
                                }}
                            />
                        </Col>
                    </Row>
                </a>
            </div>
        )
    }
}
