import { Component } from 'react'
import React from 'react'
import { PrismAppPublicModel, IPrismAppMeta } from '../api/Models'
import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'

const columns: Array<ColumnProps<{
    key: string
    linkifiedModelId: string
    appId: string
    appName: string
    url: string
    meta: IPrismAppMeta
}>> = [
    {
        title: 'Model ID',
        dataIndex: 'linkifiedModelId',
        render: (dataStringified: string) => {
            const data = JSON.parse(dataStringified)
            return (
                <a href={data.titleLink} rel="noopener noreferrer" target="_blank">
                    {data.titleText}
                </a>
            )
        }
    },
    {
        title: 'Title',
        dataIndex: 'appName'
    },
    {
        title: 'Year',
        dataIndex: ['meta','year'],
        sorter: (a: PrismAppPublicModel, b: PrismAppPublicModel) => {
            return a.meta && a.meta.year ? a.meta.year.localeCompare(b.meta.year || '') : 0
        },
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend']
    },
    {
        title: 'Type',
        dataIndex: ['meta','type'],
        sorter: (a: PrismAppPublicModel, b: PrismAppPublicModel) => {
            return a.meta && a.meta.type ? a.meta.type.localeCompare(b.meta.type || '') : 0
        },
        sortDirections: ['descend', 'ascend']
    },
    {
        title: 'Primary Condition',
        dataIndex: ['meta','primaryCondition'],
        sorter: (a: PrismAppPublicModel, b: PrismAppPublicModel) => {
            return a.meta && a.meta.primaryCondition ? a.meta.primaryCondition.localeCompare(b.meta.primaryCondition || '') : 0
        },
        sortDirections: ['descend', 'ascend']
    },
    {
        title: 'Outcomes',
        dataIndex: ['meta','outcome']
    }
]

export default class AppsTable extends Component<
    {
        apps: PrismAppPublicModel[]
    },
    {}
> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render() {
        const apps = this.props.apps
            .sort((a, b) => {
                try {
                    return  Number(a.meta.year) - Number(b.meta.year)
                } catch (err) {
                    return 0
                }
            })
            .map(it => {
                return {
                    ...it,
                    key: it.appId,
                    linkifiedModelId: JSON.stringify({
                        titleLink: it.meta.helpLink,
                        titleText: it.appId
                    })
                }
            })

        return (
            <div style={{ padding: 10 }}>
                <Table columns={columns} dataSource={apps} />
            </div>
        )
    }
}
